<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { type ButtonVariants, buttonVariants } from ".";
import { cn } from "@/lib/utils";

interface Props extends PrimitiveProps {
  variant?: ButtonVariants["variant"];
  size?: ButtonVariants["size"];
  fullwidth?: ButtonVariants["fullwidth"];
  squared?: ButtonVariants["squared"];
  class?: HTMLAttributes["class"];
  loading?: ButtonVariants["loading"];
}

const props = withDefaults(defineProps<Props>(), {
  as: "button",
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="
      cn(
        buttonVariants({ variant, size, fullwidth, loading, squared }),
        props.class,
      )
    "
  >
    <slot v-if="!loading" />
    <span v-else>
      <font-awesome icon="spinner" spin />
    </span>
  </Primitive>
</template>
